import { BarChart } from "@mantine/charts";
import {
  ColorSwatch,
  Group,
  MantineProvider,
  Text as MantineText,
  Paper,
  Stack, // rename this back to Text when converting to TS
  Title
} from "@mantine/core";
import classNames from "classnames";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { PureComponent, useState } from "react";
import { Col, Row } from "reactstrap";
import { ReferenceArea } from "recharts";
import { seriesColors } from "../../../../mantine/charts/mantine.constants";
import { mantineTheme } from "../../../../mantine/mantineTheme";
import {
  DATE_TIME_DISPLAY_FORMAT,
  TIME_DISPLAY_WITHOUT_SECONDS_FORMAT
} from "../../../../utils/dates/dates.constants";
import { Section } from "../../../BuildingBlocks/Layout/Section";
import { Button } from "../../../Buttons/Button/Button";
import loader from "../../../Loader/Loader";
import "./EnergyFlowDisplay.scss";

const initialZoomState = {
  refAreaLeft: undefined,
  refAreaRight: undefined,
  left: "dataMin",
  right: "dataMax",
  top: "dataMax",
  bottom: "dataMin"
};

class EnergyFlowDisplay extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeSeries: []
    };
  }

  render() {
    const { formatForReport, producerTableHeader, consumerTableHeader, data } =
      this.props;

    const key = Object.keys(data)[0];
    const consumerPlots = data[key].consumers;
    const generatorPlots = data[key].generators;

    const paperExtraProps = formatForReport
      ? {
          bg: "transparent",
          p: 0,
          shadow: "none"
        }
      : undefined;

    return (
      <MantineProvider theme={mantineTheme}>
        <Row className="EnergyFlowDisplay">
          <Col md="6" style={{ paddingRight: formatForReport ? "0" : null }}>
            <Paper {...paperExtraProps}>
              <Title order={3} variant="paper-header">
                {producerTableHeader}
              </Title>
              {generatorPlots.map((graph, index) => (
                <Section key={`${graph.layout.title}-${index}`}>
                  <ChartInSection
                    formatForReport={formatForReport}
                    graph={graph}
                  />
                </Section>
              ))}
            </Paper>
          </Col>
          <Col md="6" style={{ paddingLeft: formatForReport ? "0" : null }}>
            <Paper {...paperExtraProps}>
              <Title order={3} variant="paper-header">
                {consumerTableHeader}
              </Title>
              {consumerPlots.map((graph, index) => (
                <Section key={`${graph.layout.title}-${index}`}>
                  <ChartInSection
                    formatForReport={formatForReport}
                    graph={graph}
                  />
                </Section>
              ))}
            </Paper>
          </Col>
        </Row>
      </MantineProvider>
    );
  }
}

EnergyFlowDisplay.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  formatForReport: PropTypes.bool.isRequired
};

export default loader(EnergyFlowDisplay);

function ChartInSection({ graph, formatForReport }) {
  const [zoomState, setZoomState] = useState(initialZoomState);
  const [activeSeries, setActiveSeries] = useState([]);

  const isSingleDay = /^\d{2}:\d{2}:\d{2}$/.test(graph.data[0].x?.[0]);

  const series = graph.data.map((series, index) => ({
    name: index,
    label: series.name.replaceAll("<br>", " "),
    color: seriesColors[index % seriesColors.length]
  }));
  const mantineData = graph.data[0].x.map((x, xIndex) => ({
    date: isSingleDay
      ? DateTime.fromObject({
          hour: x.split(":")[0],
          minute: x.split(":")[1],
          second: x.split(":")[2]
        }).toMillis()
      : DateTime.fromISO(x).toMillis(),
    ...series.reduce(
      (result, currentSeries) => ({
        ...result,
        [currentSeries.name]: graph.data[currentSeries.name].y[xIndex]
      }),
      {}
    )
  }));

  function handleLegendToggle(seriesId) {
    setActiveSeries((prev) =>
      prev.includes(seriesId)
        ? prev.filter((id) => id !== seriesId)
        : [...prev, seriesId]
    );
  }

  function zoomIn() {
    if (
      !zoomState.refAreaLeft ||
      !zoomState.refAreaRight ||
      zoomState.refAreaLeft === zoomState.refAreaRight ||
      zoomState.refAreaRight === ""
    ) {
      setZoomState({ ...zoomState, refAreaLeft: "", refAreaRight: "" });
      return;
    }

    // xAxis domain
    if (
      zoomState.refAreaLeft &&
      zoomState.refAreaRight &&
      zoomState.refAreaLeft > zoomState.refAreaRight
    )
      [zoomState.refAreaLeft, zoomState.refAreaRight] = [
        zoomState.refAreaRight,
        zoomState.refAreaLeft
      ];

    // yAxis domain
    const allYKeys = Object.keys(mantineData[0]).filter(
      (key) => key !== "date"
    );
    const indexLeft = mantineData.findIndex(
      (entry) => entry.date === zoomState.refAreaLeft
    );
    const indexRight = mantineData.findIndex(
      (entry) => entry.date === zoomState.refAreaRight
    );
    const allVisibleValues = mantineData
      .slice(indexLeft, indexRight + 1)
      .map((entry) => [...allYKeys.map((key) => entry[key])])
      .flat();
    const bottom = Math.min(...allVisibleValues);
    const top = Math.max(...allVisibleValues);

    setZoomState({
      ...zoomState,
      refAreaLeft: undefined,
      refAreaRight: undefined,
      left: zoomState.refAreaLeft,
      right: zoomState.refAreaRight,
      top: Math.ceil(top),
      bottom: Math.floor(bottom)
    });
  }

  function zoomOut() {
    setZoomState(initialZoomState);
  }

  return (
    <Group align="start" className="ChartInSection" gap={0}>
      <Stack w="80%">
        <Title order={5}>{graph.layout.title.text}</Title>
        <Group>
          <Button
            className="zoom-out-btn"
            disabled={
              zoomState.left === "dataMin" && zoomState.right === "dataMax"
            }
            size="small"
            onClick={zoomOut}
          >
            Zoom zurücksetzen
          </Button>
        </Group>
        <BarChart
          barChartProps={{
            onMouseDown: (e) => {
              setZoomState({
                ...zoomState,
                refAreaLeft: e.activeLabel || "",
                refAreaRight: e.activeLabel || ""
              });
            },
            onMouseMove: (e) =>
              zoomState.refAreaLeft &&
              setZoomState({ ...zoomState, refAreaRight: e.activeLabel || "" }),
            onMouseUp: zoomIn
          }}
          barProps={(series) => ({
            hide: activeSeries.includes(series.name)
          })}
          className="plot-container"
          data={mantineData}
          dataKey="date"
          h={"355px"}
          referenceLines={[
            { x: zoomState.refAreaLeft },
            { x: zoomState.refAreaRight }
          ]}
          series={series}
          tooltipProps={{
            content: ({ label: timestamp, payload }) => (
              <ChartTooltip
                allSeriesLabels={series.map((entry) => entry.label)}
                payload={payload}
                timestamp={
                  typeof timestamp === "number"
                    ? DateTime.fromMillis(timestamp).toFormat(
                        isSingleDay
                          ? TIME_DISPLAY_WITHOUT_SECONDS_FORMAT
                          : DATE_TIME_DISPLAY_FORMAT
                      )
                    : timestamp
                }
              />
            )
          }}
          type="stacked"
          w={formatForReport ? "525px" : "100%"}
          xAxisProps={{
            allowDataOverflow: true,
            domain: [zoomState.left, zoomState.right],
            ticks: mantineData.map((entry) => entry.date),
            tickFormatter: (timeStamp) =>
              DateTime.fromMillis(timeStamp).toFormat(
                isSingleDay
                  ? TIME_DISPLAY_WITHOUT_SECONDS_FORMAT
                  : DATE_TIME_DISPLAY_FORMAT
              ),
            type: "number"
          }}
          yAxisLabel={graph.layout.yaxis.title.text}
          yAxisProps={{
            domain: [zoomState.bottom, zoomState.top],
            tickFormatter: (value) =>
              (value * 10) % 10 !== 0 || (value * 100) % 100 !== 0
                ? value.toFixed(2)
                : value
          }}
        >
          <ReferenceArea
            x1={zoomState.refAreaLeft}
            x2={zoomState.refAreaRight}
            yAxisId={"left"}
          />
        </BarChart>
      </Stack>
      <ChartLegend
        activeSeries={activeSeries}
        series={series}
        onClick={handleLegendToggle}
      />
    </Group>
  );
}

function ChartTooltip({ allSeriesLabels, timestamp, payload }) {
  if (!payload) return null;

  return (
    <Paper
      className="EnergyFlowDisplay ChartTooltip"
      px="md"
      py="sm"
      radius="md"
      shadow="md"
      withBorder
    >
      <MantineText fw={500} mb={5}>
        {timestamp}
      </MantineText>
      {payload.map((item) => (
        <Group justify="space-between" key={item.name}>
          <Group gap="sm">
            <div className="dot" style={{ backgroundColor: item.color }} />
            <MantineText fz="sm">
              {allSeriesLabels[item.name].replaceAll("<br>", " ") ||
                "Unbekannt"}
            </MantineText>
          </Group>
          <MantineText fz="sm">{item.value.toFixed(2)}</MantineText>
        </Group>
      ))}
    </Paper>
  );
}

function ChartLegend({ series, activeSeries, onClick }) {
  return (
    <Stack className="ChartLegend" gap={0} mah={500} pl="md" w="20%">
      {series.map((item) => {
        const isActive = !activeSeries.includes(item.name);
        return (
          <Button
            className={classNames(
              "legend-item",
              isActive ? "active" : "inactive"
            )}
            key={item.name}
            noBackground
            noBorder
            onClick={() => onClick(item.name)}
          >
            <ColorSwatch
              bd={isActive ? "none" : "2px solid " + item.color}
              color={isActive ? item.color : "#fff"}
              size="var(--mantine-font-size-md)"
            />
            <MantineText className="legend-label" ms="sm">
              {item.label}
            </MantineText>
          </Button>
        );
      })}
    </Stack>
  );
}
